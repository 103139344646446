<template>
  <div>
    <vx-data-list/>
  </div>
</template>
<script>
  import vxDataList from '../components/data-list-users/list-view/DataListListView';

  export default {
    components: {
      "vx-data-list": vxDataList
    }
  }
</script>

