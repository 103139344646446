<template>
  <div>
    <vs-prompt
      @accept="submitData"
      :title="title"
      :active.sync="isDialogActiveLocal"
      accept-text="Mudar Fase"
      cancel-text="Cancelar">

      <div>
        <div class="vx-col md:12 w-full mt-2">
          <p>Este contrato está na fase: <strong>{{
            this.translatePhase(contract.currentPhase) }}</strong> deseja alterar para qual fase?
          </p>
        </div>
        <div class="vx-col md:12 w-full mt-2">
          <ul class="leftx" v-if="contract.currentPhase === 'implantation'">
            <li>
              <vs-radio color="warning" v-model="nextPhase" vs-name="contractPhase"
                        vs-value="investigation">Apuração
              </vs-radio>
            </li>
          </ul>

          <ul class="leftx" v-if="contract.currentPhase === 'investigation'">
            <li>
              <vs-radio color="success" v-model="nextPhase" vs-name="contractPhase"
                        vs-value="implantation">Implantação
              </vs-radio>
            </li>
            <li>
              <vs-radio color="danger" v-model="nextPhase" vs-name="contractPhase"
                        vs-value="fixed_remuneration">Remuneração Fixa
              </vs-radio>
            </li>
          </ul>

          <ul class="leftx" v-if="contract.currentPhase === 'fixed_remuneration'">
            <li>
              <vs-radio color="success" v-model="nextPhase" vs-name="contractPhase"
                        vs-value="implantation">Implantação
              </vs-radio>
            </li>
            <li>
              <vs-radio color="warning" v-model="nextPhase" vs-name="contractPhase"
                        vs-value="investigation">Apuração
              </vs-radio>
            </li>
          </ul>
        </div>
      </div>
    </vs-prompt>

  </div>
</template>

<script>
  export default {
    props: {
      data: {
        type: Object,
        required: true
      },
      isActivePromptUpdatePhase: {
        type: Boolean,
        required: true
      },
    },
    data() {
      return {
        dataContractId: null,
        nextPhase: ''
      }
    },
    computed: {
      contract() {
        return this.data
      },
      title() {
        return `Contrato: ${this.contract.numberContract}`
      },
      isDialogActiveLocal: {
        get() {
          return this.isActivePromptUpdatePhase
        },
        set(val) {
          if (!val) {
            this.$emit('closeDialog')
          }
        }
      }
    },
    methods: {
      translatePhase(phase) {
        return this.$options.filters.translatePhase(phase)
      },
      submitData() {
        this.$validator.validateAll().then(result => {
          if (result) {
            const payload = {
              'contractId': this.contract.contractId,
              'phase': this.nextPhase
            }

            if (payload) {
              this.$store.dispatch("dataList/updatePhase", payload)
                .then(() => {
                  this.$vs.notify({
                    color: 'success',
                    title: 'Sucesso',
                    text: `Status do contrato ${this.contract.numberContract} foi atualizado`
                  })
                })
                .catch(() => {
                  this.$vs.notify({
                    color: 'danger',
                    title: 'Erro',
                    text: 'Ao mudar o contrato de fase.'
                  })
                })
            }
            this.$emit('closeSidebar')
          }
        })

      }
    },

  }
</script>
<style>
  .vs-dialog {
    max-width: 450px !important;
  }
</style>
